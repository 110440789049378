<script setup lang="ts">
import { vElementVisibility } from "@vueuse/components";
import VAppLink from "@magnit/core/src/components/VAppLink/VAppLink.vue";
import VText from "@magnit/core/src/components/VText/VText.vue";
import VLabel from "@magnit/core/src/components/VLabel/VLabel.vue";
import type { IPartnersOffer } from "~/typings/api/partners";

const props = defineProps<{
  partner: IPartnersOffer;
  showSticker?: boolean;
}>();

defineEmits<{
  onClick: [];
  onVisibility: [visibility: boolean];
}>();

const partnerLink = computed(() => `${Routes.Partners}/${props.partner.id}`);
</script>

<template>
  <VAppLink v-if="partner" :to="partnerLink" class="link pl-hover-base">
    <div
      v-element-visibility="(v) => $emit('onVisibility', v)"
      class="card"
      @click="$emit('onClick')"
    >
      <div class="logo">
        <AppImage :src="partner.partnerLogo" :alt="partner.title" loading="lazy" />
        <VLabel
          v-if="showSticker && partner?.sticker?.activity"
          :size="{ xs: 'xs', s: 's' }"
          :bg-color="partner?.sticker.background_color"
          :text-color="partner?.sticker.text_color"
          class="sticker"
        >
          {{ partner?.sticker.word }}
        </VLabel>
      </div>
      <div class="container">
        <VText font="body-small-regular-low" tag="div" color="secondary" class="title">
          {{ partner.title }}
        </VText>
        <VText font="body-large-regular-low" tag="div" class="description">
          {{ partner.description }}
        </VText>
      </div>
    </div>
  </VAppLink>
</template>

<style scoped lang="postcss">
.link {
  display: block;
}

.card {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: var(--pl-unit-x2);
  padding-bottom: var(--pl-unit-x4);

  @media (--pl-viewport-from-s) {
    padding-bottom: var(--pl-unit-x3);
  }

  @media (--pl-viewport-from-s) {
    padding-bottom: var(--pl-unit-x3);
  }

  @media (--pl-viewport-from-m) {
    padding-bottom: var(--pl-unit-x1);
  }
}

.logo {
  position: relative;
  padding: var(--pl-unit-x2);
  border-radius: var(--pl-unit-x4);
  background: var(--pl-surface-muted-default);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 1;

  :deep(img) {
    border-radius: var(--pl-unit-x3);
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  .sticker {
    position: absolute;
    left: var(--pl-unit-x2);
    top: var(--pl-unit-x2);
  }
}

.container {
  padding: 0 var(--pl-unit-x1);
  display: flex;
  flex-direction: column;
  gap: var(--pl-unit-x2);
}

.title {
  -webkit-line-clamp: 2; /* Число отображаемых строк */
  display: -webkit-box; /* Включаем флексбоксы */
  -webkit-box-orient: vertical; /* Вертикальная ориентация */
  overflow: hidden; /* Обрезаем всё за пределами блока */
}

.description {
  -webkit-line-clamp: 3; /* Число отображаемых строк */
  display: -webkit-box; /* Включаем флексбоксы */
  -webkit-box-orient: vertical; /* Вертикальная ориентация */
  overflow: hidden; /* Обрезаем всё за пределами блока */
}
</style>
